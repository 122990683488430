<script setup>
const cookieClickedAccept = () => {
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  const GTAG_ID = import.meta.env.VITE_GTAG_ID;

  gtag('consent', 'update', {
    ad_storage: 'denied',
    analytics_storage: 'granted'
  });

  gtag('js', new Date());
  gtag('config', GTAG_ID);
};

const locale =
  window.location.pathname.includes('/ja/') ||
  window.location.pathname === '/ja'
    ? 'ja'
    : 'en';
</script>

<template>
  <vue-cookie-accept-decline
    :debug="false"
    :disableDecline="false"
    :showPostponeButton="false"
    @clicked-accept="cookieClickedAccept"
    elementId="ga-confirmation"
    position="bottom-left"
    ref="ga-confirmation"
    transitionName="slideFromBottom"
    type="floating"
  >
    <!-- Optional -->
    <template v-if="locale === 'ja'" #message>
      ウェブサイトを動作させるために、重要なクッキーを使用しています。
      ウェブサイトの改善のために、分析クッキーを使用したいです。
    </template>
    <template v-else #message>
      We use some essential cookies to make this service work. We would also
      like to use analytics cookies to make improvements to the website.
    </template>

    <!-- Optional -->
    <template #declineContent>
      {{ locale === 'ja' ? '辞退する' : 'No thanks' }}
    </template>

    <!-- Optional -->
    <template #acceptContent>
      {{ locale === 'ja' ? '受け入れる' : 'Allow' }}
    </template>
  </vue-cookie-accept-decline>
</template>
