import { createApp } from 'vue';
import App from './App.vue';

import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

const node = document.getElementById('ga');

const acceptGaCookies =
  window.localStorage['vue-cookie-accept-decline-ga-confirmation'];

if (node !== null) {
  const app = createApp(App);

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  const GTAG_ID = import.meta.env.VITE_GTAG_ID;

  if (acceptGaCookies === 'accept') {
    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'granted'
    });
  } else {
    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied'
    });
  }

  gtag('js', new Date());

  gtag('config', GTAG_ID);

  app.component('vue-cookie-accept-decline', VueCookieAcceptDecline);
  app.mount('#ga');
}
